import React, {
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react"
import styled from "styled-components"
import { ScrollContext } from "../context/Scroll"

const Container = styled.div`
  padding: var(--gutter-s);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  transition: background-color ease-in-out 300ms;

  &.scrolled {
    background-color: white;
  }

  @media (${prop => prop.theme.screenSizes.md}) {
    display: grid;
    width: 100vw;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
`

const Logo = styled.img`
  width: 170px;
  position: relative;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
`

const Link = styled.a`
  font-size: 20px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 0%;
    transition: all ease-in-out 300ms;
    height: 1.3px;
    background-color: ${props => props.theme.colors.text};
    position: absolute;
    bottom: -2px;
    transform: translate(0%, 100%);
  }

  &:hover,
  &.active {
    cursor: pointer;
    &:before {
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    font-size: 15px;
    margin-left: 4vw;
  }

  @media (min-width: 1400px) {
    margin-left: 2vw;
    font-size: 20px;
  }

  @media (max-width: 992px) {
    font-size: 7vw;
    margin: 10px 0px;
    opacity: 0;
    transition: all ease-in-out 300ms;
    transition-delay: 300ms;
  }
`

const LinkContainer = styled.div`
  display: flex;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: fixed;
    transform: translate(-100%, 0%);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
    transition: all ease-in-out 300ms;

    &.active {
      transform: translate(0%, 0%);

      ${Link} {
        opacity: 1;
      }
    }
  }
`

const ToggleContainer = styled.div<{ className: string }>`
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -680px;
  }
  &.cross path#middle {
    stroke-dashoffset: -110px;
    stroke-dasharray: 1px 220px;
  }

  background-color: transparent;
  user-select: none;
  &:focus,
  &:active {
    background-color: transparent;
  }
`

const Toggle = styled.svg`
  display: none;
  position: absolute;
  z-index: 10;
  top: var(--gutter-s);
  right: 0;
  width: 80px;
  height: 50px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);

  path {
    fill: none;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 25px;
    stroke-linecap: round;
    stroke: ${props => props.theme.colors.text};
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  @media (max-width: 992px) {
    display: block;
  }
`

const Header: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const frameId = useRef<number>(0)
  const { sections, sectionInView, scrollToSection } = useContext(ScrollContext)
  const [menuActive, setMenuActive] = useState(false)

  const renderLinks = useCallback(() => {
    return sections.map((section, index) => (
      <Link
        key={`section-${index}`}
        onClick={(): void => {
          if (scrollToSection) {
            scrollToSection(section.name)
            setMenuActive(false)
          }
        }}
        className={sectionInView === section.name ? "active" : ""}
      >
        {section.name}
      </Link>
    ))
  }, [sections, scrollToSection, sectionInView])

  useEffect(() => {
    const checkPosition = () => {
      if (window.pageYOffset > 100) {
        containerRef.current?.classList.add("scrolled")
      } else {
        containerRef.current?.classList.remove("scrolled")
      }
    }

    const onScroll = () => {
      frameId.current = window.requestAnimationFrame(checkPosition)
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.cancelAnimationFrame(frameId.current)
    }
  }, [])

  return (
    <>
      <Container ref={containerRef}>
        <a
          onClick={(): void => {
            window.scrollTo({ top: 0, behavior: "smooth" })
          }}
        >
          <Logo
            src={require("../images/logos/loancircle-logo-blue.svg")}
            alt="Loan Circle logo"
          />
        </a>
        <LinkContainer className={menuActive ? "active" : ""}>
          {renderLinks()}
          <Link
            key={`section-4`}
            href="https://loancircle.creditgateway.co.za/Identity/Account/Register"
          >
            Apply Now
          </Link>
          <Link
            key={`section-4`}
            href="https://loancircle.creditgateway.co.za/Identity/Account/Login"
          >
            Sign In
          </Link>
        </LinkContainer>
        <ToggleContainer
          onClick={() => {
            setMenuActive(!menuActive)
          }}
          className={menuActive ? "cross" : ""}
        >
          <Toggle viewBox="0 0 800 600">
            <path
              d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
              id="top"
            ></path>
            <path d="M300,300 L540,300" id="middle"></path>
            <path
              d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
              id="bottom"
              transform="translate(480, 280) scale(1, -1) translate(-480, -318) "
            ></path>
          </Toggle>
        </ToggleContainer>
      </Container>
    </>
  )
}

export default Header
