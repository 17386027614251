export const theme = {
  listButtonOffset: "40px",
  colors: {
    landingBg: "#d7f2fa",
    buttonBg: "#54e2da",
    buttonBgHover: "#1c1c34",
    buttonText: "#ffffff",
    employeesBg: "#1c1c34",
    employeesItems: "#bbbbbf",
    formBg: "#DBF3FA",
    headingsText: "#1c1c34",
    footerSubtext: "#61617d",
    text: "#1C1C34",
    quoteText: "#54e2da",
    footerBg: "#1c1c34",
    footerSubText: "#61617d",
  },
  screenSizes: {
    xs: "max-width: 768px",
    sm: "min-width: 768px",
    md: "min-width: 992px",
    lg: "min-width: 1200px",
  },
}
