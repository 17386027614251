import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./theme"

import { ScrollProvider } from "./context/Scroll"

import { gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

interface RootElementProps {
  element: React.ReactElement[] | React.ReactElement
}

const WrapRootElement: React.FunctionComponent<RootElementProps> = ({
  element,
}: RootElementProps) => {
  return (
    <ScrollProvider>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </ScrollProvider>
  )
}

export default WrapRootElement
