import React from "react"
import Layout from "./components/layout"

interface PageElementProps {
  element: React.ReactElement[] | React.ReactElement
}

const WrapPageElement: React.FunctionComponent<PageElementProps> = ({
  element,
  ...props
}: PageElementProps) => {
  return <Layout {...props}>{element}</Layout>
}

export default WrapPageElement
