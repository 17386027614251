import React, { ReactNode } from "react"

import Header from "./header"
import Footer from "./footer"

interface Props {
  children: ReactNode
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  )
}

export default Layout
