import React, { useContext, useCallback } from "react"
import { ScrollContext } from "../context/Scroll"

import styled from "styled-components"

import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.footer`
  background-color: ${props => props.theme.colors.footerBg};
  padding: var(--gutter-s);

  @media (${prop => prop.theme.screenSizes.md}) {
  }
`

const SubTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;

  @media (${prop => prop.theme.screenSizes.md}) {
    padding-top: 120px;
    flex-direction: row;
  }
`

const SubText = styled.p`
  color: ${props => props.theme.colors.footerSubText};
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`

const NCRLogo = styled(GatsbyImage)`
  height: 30px;
  width: 94px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;

  img {
    object-fit: contain !important;
  }
  @media (${prop => prop.theme.screenSizes.md}) {
    margin-top: 0px;
  }
`

const MenuContainer = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.screenSizes.md}) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20vh;
  }
`

const Logo = styled.img`
  width: 170px;

  &:hover {
    cursor: pointer;
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.screenSizes.md}) {
    flex-direction: row;
  }
`

const Link = styled.a`
  font-size: 20px;
  position: relative;
  color: white;
  margin-top: 25px;
  text-align: center;

  &:before {
    content: "";
    display: block;
    width: 0%;
    transition: all ease-in-out 300ms;
    height: 1.3px;
    background-color: white;
    position: absolute;
    bottom: -2px;
    left: 0;
    transform: translate(0%, 100%);
  }

  &:hover,
  &.active {
    cursor: pointer;
    color: white;
    &:before {
      width: 100%;
    }
  }

  @media (${props => props.theme.screenSizes.md}) {
    margin-top: 0px;
  }

  @media (min-width: 992px) {
    font-size: 15px;
    margin-left: 4vw;
  }
  @media (min-width: 1400px) {
    margin-left: 6vw;
    font-size: 20px;
  }
`

const Footer = () => {
  const { sections, scrollToSection } = useContext(ScrollContext)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logos/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 361, maxHeight: 88, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ncrLogo: file(relativePath: { eq: "logos/ncr-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 94, maxHeight: 50, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const renderLinks = useCallback(() => {
    return sections.map((section, index) => (
      <Link
        key={`section-${index}`}
        onClick={(): void => {
          if (scrollToSection) {
            scrollToSection(section.name)
          }
        }}
      >
        {section.name}
      </Link>
    ))
  }, [sections, scrollToSection])

  return (
    <Container>
      <MenuContainer>
        <a
          onClick={(): void => {
            window.scrollTo({ top: 0, behavior: "smooth" })
          }}
        >
          <Logo
            src={require("../images/logos/loancircle-logo-white.svg")}
            alt="Loan Circle Logo"
          ></Logo>
        </a>
        <LinkContainer>{renderLinks()}</LinkContainer>
      </MenuContainer>
      <SubTextContainer>
        <SubText>
          © LOANCIRCLE {new Date().getFullYear()}. ALL RIGHTS RESERVED.
        </SubText>
        <SubText>
          REGISTERED CREDIT PROVIDER NCRCP 10950
          <NCRLogo
            fluid={data.ncrLogo.childImageSharp.fluid}
            alt="National Credit Regulator (NCR) logo"
          />
        </SubText>
      </SubTextContainer>
    </Container>
  )
}

export default Footer
